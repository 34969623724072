import React, { useState, useEffect } from 'react';
import { useSwipeable } from 'react-swipeable';
import axios from 'axios';
import logo from './logo-white.png';

import { ReactComponent as FacebookLogo } from './facebook.svg';
import { ReactComponent as YoutubeLogo } from './youtube.svg';
import { ReactComponent as VimeoLogo } from './vimeo.svg';

import MainMovie from './components/main-movie/main-movie.component';
import MoviesList from './components/movies-list/movies-list.component';
import './App.css';

const App = () => {
	const [movies, setMovies] = useState([]);
	const [nextPageToken, setNextPageToken] = useState('');
	const [prevPageToken, setPrevPageToken] = useState('');
	const [mainMovie, setMainMovie] = useState(null);
	const [renderLoading, setRenderLoading] = useState(true);
	const [renderApp, setRenderApp] = useState(false);

	const changeMainMovie = (movie) => {
		setMainMovie(movie);
	}

	const fetchData = async () => {
		let maxResults = 5;
		if(window.innerWidth <= 740) {
			maxResults = 4;
		}
		if(window.innerWidth <= 500) {
			maxResults = 3;
		}

		const response = await axios.get('https://www.googleapis.com/youtube/v3/playlistItems', {
			params: {
				part: 'snippet',
				key: 'AIzaSyB5TVK8VikT8p5ef9hN4Xjq9KmuMXTTx5g',
				maxResults: maxResults,
				playlistId: 'PL9KWnhblEd24EQ1MXHxa8BUsCWcSLl60e'
			}
		});
		setMovies(response.data.items);
		setMainMovie(response.data.items[0]);
		setNextPageToken(response.data.nextPageToken);
		setPrevPageToken(response.data.prevPageToken);
	}

	const fetchNextPage = async () => {
		let maxResults = 5;
		if(window.innerWidth <= 740) {
			maxResults = 4;
		}
		if(window.innerWidth <= 500) {
			maxResults = 3;
		}
		const response = await axios.get('https://www.googleapis.com/youtube/v3/playlistItems', {
			params: {
				part: 'snippet',
				key: 'AIzaSyB5TVK8VikT8p5ef9hN4Xjq9KmuMXTTx5g',
				maxResults: maxResults,
				pageToken: nextPageToken,
				playlistId: 'PL9KWnhblEd24EQ1MXHxa8BUsCWcSLl60e'
			}
		});
		setMovies(response.data.items);
		setNextPageToken(response.data.nextPageToken);
		setPrevPageToken(response.data.prevPageToken);
	}

	const fetchPrevPage = async () => {
		let maxResults = 5;
		if(window.innerWidth <= 740) {
			maxResults = 4;
		} 
		if(window.innerWidth <= 500) {
			maxResults = 3;
		}
		const response = await axios.get('https://www.googleapis.com/youtube/v3/playlistItems', {
			params: {
				part: 'snippet',
				key: 'AIzaSyB5TVK8VikT8p5ef9hN4Xjq9KmuMXTTx5g',
				maxResults: maxResults,
				pageToken: prevPageToken,
				playlistId: 'PL9KWnhblEd24EQ1MXHxa8BUsCWcSLl60e'
			}
		});
		setMovies(response.data.items);
		setNextPageToken(response.data.nextPageToken);
		setPrevPageToken(response.data.prevPageToken);
	}

	const handlers = useSwipeable({
		onSwipedRight: eventData => fetchPrevPage(),
		onSwipedLeft: eventData => fetchNextPage(),
		trackMouse: true,
		preventDefaultTouchmoveEvent: true
	})

	useEffect(() => {
		fetchData();
		const loadingTimer = setTimeout(() => {
			setRenderLoading(false);
		}, 1500);
		const appTimer = setTimeout(() => {
			setRenderApp(true);
		}, 2000);
		return () => {
			clearTimeout(loadingTimer);
			clearTimeout(appTimer);
		}
	}, []);

	return (
		<div className="container">
			<div className={`loading-container ${renderLoading ? 'fadeIn' : 'fadeOut' }`}>
				<img className="loading-logo" src={logo} alt="Logo"></img>
				<div className="loading-list">
					<span className="loading-list__item">Teledysk</span>
					<span className="loading-list__item">Animacja 2D/3D</span>
					<span className="loading-list__item">Komercja</span>
				</div>
			</div>

			<div className={`app-container ${renderApp ? 'fadeIn' : 'fadeOut'}`}>
				<div className="logo-container">
					<img className="logo-container__logo" src={logo} alt="Logo"></img>
				</div>
				<div className="movies-container">
					<MainMovie movie={mainMovie} />
					<MoviesList movies={movies} changeMainMovie={changeMainMovie} nextPage={fetchNextPage} prevPage={fetchPrevPage} handlers={handlers} />
					<span class="movies-container__cta">Przesuń po playliście lub kliknij jedną ze strzałek, by zobaczyć więcej filmów!</span>
				</div>
				<div className="footer">
					<div className="icons">
						<a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/user/ThePNmedia" ><YoutubeLogo className="icons--icon" /></a>
						<a target="_blank" rel="noopener noreferrer" href="https://vimeo.com/pnmedia"><VimeoLogo className="icons--icon" /></a>
						<a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/thepnmedia/"><FacebookLogo className="icons--icon" /></a>
					</div>	
					<div className="contact">
						<div className="contact__email">
							<span className="contact--label">Email: </span>
							<span className="contact--text">thepnmedia@gmail.com</span>
						</div>
						<div className="contact__phone">
							<span className="contact--label">Telefon: </span>
							<span className="contact--text">508 423 255</span>
						</div>
					</div>
					<div className="copyright">
						<span>Copyright © 2010 - 2021 by PNMedia.</span>
						<span className="copyright--creator">Designed & Developed by <a style={{textDecoration: 'none', color: 'inherit'}} href="https://www.facebook.com/profile.php?id=100002275685254">Jakub Niedziółka</a></span>
					</div>
				</div>
			</div>
		</div>
	);
};

export default App;
