import React from 'react';

import './movie-thumbnail.styles.css';

const MovieThumbnail = ({ movie, changeMainMovie }) => {
    const changeMovie = () => {
        changeMainMovie(movie);
    }

    const calculateThumbnail = () => {
        if(movie.snippet.thumbnails.maxres) {
            return movie.snippet.thumbnails.maxres.url;
        } else if(movie.snippet.thumbnails.standard) {
            return movie.snippet.thumbnails.standard.url;
        } else if(movie.snippet.thumbnails.high) {
            return movie.snippet.thumbnails.high.url;
        } else if(movie.snippet.thumbnails.medium) {
            return movie.snippet.thumbnails.medium.url;
        } else if(movie.snippet.thumbnails.default) {
            return movie.snippet.thumbnails.default.url;
        }
    }

    return (
        <div className="movie-thumbnail" style={{backgroundImage: `url(${calculateThumbnail()})`}} onClick={changeMovie}>
            <div className="movie-thumbnail__background">
                <span className="movie-thumbnail__text">{movie.snippet.title}</span>
            </div>
        </div>
    );
};

export default MovieThumbnail;