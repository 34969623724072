import React from 'react';
import MovieThumbnail from '../movie-thumbnail/movie-thumbnail.component';
import {ReactComponent as LeftArrow} from '../../left-arrow.svg';
import {ReactComponent as RightArrow} from '../../right-arrow.svg';

import './movies-list.styles.css';

const MainMovie = ({ movies, changeMainMovie, nextPage, prevPage, handlers }) => {
    return (
        <div className="movies-list" {...handlers} >
            <LeftArrow className="movies-list__arrow" onClick={prevPage} />
            {
                movies.map((movie, i) => {
                    return <MovieThumbnail key={i} movie={movie} changeMainMovie={changeMainMovie} />
                })
            }
            <RightArrow className="movies-list__arrow" onClick={nextPage} />
        </div>
    );
};

export default MainMovie;