import React from 'react';

import './main-movie.styles.css';

const MainMovie = ({ movie }) => {
    return (
        <div className="main-movie">
            {
               movie && <iframe className="main-movie__video" src={`https://www.youtube.com/embed/${movie.snippet.resourceId.videoId}`} title={movie.snippet.title}></iframe>
            }
        </div>
    )
}

export default MainMovie;